import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_2 = { class: "mt-10 grid grid-cols-2 gap-6" }
const _hoisted_3 = { class: "mt-8 grid grid-cols-2 gap-6" }
const _hoisted_4 = { class: "mt-8" }
const _hoisted_5 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_AvatarUpload = _resolveComponent("AvatarUpload")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (!_ctx.loading)
    ? (_openBlock(), _createBlock(_component_BaseCard, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('platform.profile.edit_profile')), 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.givenName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.givenName) = $event)),
              label: _ctx.$t('platform.profile.given_name'),
              error: _ctx.errors.given_name ? _ctx.errors.given_name[0] : null,
              "data-dusk": "given-name"
            }, null, 8, ["modelValue", "label", "error"]),
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.familyName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.familyName) = $event)),
              label: _ctx.$t('platform.profile.family_name'),
              error: _ctx.errors.family_name ? _ctx.errors.family_name[0] : null,
              "data-dusk": "family-name"
            }, null, 8, ["modelValue", "label", "error"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
              label: _ctx.$t('platform.profile.email'),
              type: "email",
              error: _ctx.errors.email ? _ctx.errors.email[0] : null,
              "data-dusk": "email"
            }, null, 8, ["modelValue", "label", "error"]),
            _createVNode(_component_BaseSelect, {
              modelValue: _ctx.selectedLanguage,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
              label: _ctx.$t('platform.profile.language'),
              options: _ctx.languages,
              "data-dusk": "languages"
            }, null, 8, ["modelValue", "label", "options"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createBlock(_component_AvatarUpload, {
              key: _ctx.avatarComponentKey,
              id: _ctx.avatarId,
              "onUpdate:id": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.avatarId) = $event)),
              onLoading: _ctx.handleAvatarLoading,
              onRefreshAvatarUploadComponent: _ctx.refreshAvatarComponent
            }, null, 8, ["id", "onLoading", "onRefreshAvatarUploadComponent"]))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseButton, {
              "data-dusk": "save-button",
              size: "large",
              loading: _ctx.saving || _ctx.avatarLoading,
              onClick: _ctx.save
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
              ]),
              _: 1
            }, 8, ["loading", "onClick"]),
            _createVNode(_component_BaseButton, {
              size: "large",
              color: "ghost",
              disabled: _ctx.saving || _ctx.avatarLoading,
              class: "ml-4",
              onClick: _ctx.cancel
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}